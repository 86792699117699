<template>
  <product :title="title"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/qV32P9"
    is-bus-city1
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'gtr-special',
  components: {
    Product
  },
  data () {
    return {
      title: 'Amazfit GTR  42mm 璀璨特别版',
      slogan: '奥地利方晶锆石奢华工艺 | 意大利进口牛皮表带 | 专属奢华表盘<br/> 12 天强劲续航 | 12 种运动模式 | 百城公交卡 | NFC 模拟门卡',
      price: 999,
      colors: [
        '璀璨版'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/gtr-special/swiper/1.png',
          CDN_URL + '/images/product/gtr-special/swiper/2.png',
          CDN_URL + '/images/product/gtr-special/swiper/3.png',
          CDN_URL + '/images/product/gtr-special/swiper/4.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/gtr-special/detail/s01.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s02.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s03.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s04.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s05.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s06.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s07.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s08.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s09.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s10.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s11.jpg',
        CDN_URL + '/images/product/gtr-special/detail/s12.jpg',
        CDN_URL + '/images/common/zepp-black.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/gtr-special/detail/s14.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
